body{
    font-family: IBM Plex Sans,sans-serif !important;
}
.paddingtop5{
    padding-top: 5px;
}
.crd-ctab{
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;   
}
.nav-pills .nav-link {
    max-width: 450px;
    height: 78px;
    background: #FFFFFF;
    border: 1px solid #C6C6C6;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 12px !important;
}
.nav-pills .nav-link.active, 
.nav-pills .show>.nav-link {
    color: #437FED;
    background-color: #007bff;
    background: #F2F4FE;
    border: 1px solid #98B5EA;
}
 /* .nav-pills .nav-link.active:before {
    content: "";
    background-color: #007bff;
    background: #F2F4FE;
    border: 1px solid #98B5EA;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    top: 0;
    right: 0;
    z-index: 1;
    transform: rotateZ(45deg);
} */
.nav-link .avatar-lg {
    /* width: 105px; */
    height: auto;
}
.custom-h5{
    border-bottom: 1px solid #C6C6C6;
    padding-bottom: 4px;
    font-size: 14px !important;
    line-height: 21px;
}
.connection-info {
    background: #FFFFFF;
    border: 1px solid #C6C6C6;
    box-sizing: border-box;
    border-radius: 8px;
    max-width: 690px;
    padding-bottom: 10px !important;
}
.no-padding{
    padding: 0;
}
.card-titlenew {
    height: 31px;
    background: #F2F2F2;
    border-radius: 8px 8px 0px 0px !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 31px;
    color: #000000;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #C6C6C6;
}
.connector-status{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 31px;
}
.connector-status .stagednew {
    color: #DC332E;
    padding: 0 5px;
}
.connector-status .productionnew{
    color: #3069d2;
    padding: 0 5px;
}
.moreiconwithdot{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #999999;
    float: right;
    margin-top: 4px;
    line-height: 19px;
    text-align: center;
    margin-left: 10px;
    color: #999999;
}

.connectorshotdesc th,
.connectorshotdesc td {
    border: none;
    padding: 0;
    font-weight: 400;
    font-size: 12px;
    color: #999999;
    padding: 3px 0;
}
.connectorshotdesc td{
    color: #444444;
}
.connectorshotdesc {
    border-bottom: 1px solid #C6C6C6;
    padding-bottom: 5px;
}
.no-border{
    border: none;
}
.no-boxshadow{
    box-shadow: none;
}
.subtitleNew{
    font-size: 14px;
    line-height: 40px;
}
.businessentity{
    height: 83px;
    background: #F2F4FE;
    border-radius: 8px;
}
.chat-list li.active a {
    background-color: transparent;
    border-color: transparent;
}
.card.bg-info {
    background-color: #F2F4FE !important;
}
.businessentity-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    text-align: center;
    color: #000000 !important;
    margin-bottom: 0px;
}
.erpCircle,
.ecommCircle{
    width: 49px;
    height: 49px;
    background-image: url("../images/erpEllipse.png");
    text-align: center;
}

.endtext {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 17.5897px;
    line-height: 50px;
    color: #437FED;
}
.erptoecommsync {
    width: 50px;
    height: 13px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: absolute;
    z-index: 999;
    margin-left: 65px;
    margin-top: 3px;
    color: #437FED;
    font-size: 12px;
    line-height: 14px;
}
.ecommtoerpsync {
    width: 50px;
    height: 13px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: absolute;
    z-index: 999;
    margin-top: 3px;
    color: #437FED;
    font-size: 12px;
    line-height: 14px;
    right: 132px;
    margin-top: 31px;
}
.connectortab .connector-active{
    display: none;
}
.connectortab.active .connector-inactive{
    display: none;
}
.connectortab.active .connector-active{
    display: flex;
}
/* .connectortab.active .selectionProcessnew:before {
    content: "";
    background-image: url(../../assets/images/rectangle-right-arrow.svg);
    display: block;
    background-position: right top;
    background-repeat: no-repeat;
    position: absolute;
    right: 3px;
    top: -2px;
    width: 50px;
    height: 79px;
} */
.mn-dashboard .nav-pills .nav-link.active {
    background-image: url(../images/some-arrow.png);
    background-repeat: no-repeat;
    background-position: top right;
    background-color: transparent;
    border: 0;
    width: 100% !important;
    max-width: 100% !important;
    background-size: 100% 100%;
}

/* .connectortab:nth-child(odd).active:after {
    content: "";
    background-image: url(../../assets/images/rectangle-right-arrow.svg);
    float: right;
    width: 30px;
    top: -93px;
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    right: -38px;
    height: 79px;
}
.connectortab:nth-child(even).active:after {
    content: "";
    background-image: url(../../assets/images/rectangle-right-arrow.svg);
    float: right;
    width: 26px;
    position: relative;
    position: relative;
    background-repeat: no-repeat;
    background-position: right;
    height: 80px;
    top: -94px;
    right: -35px;
} */
.connectortab.active {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px !important;
}
button.new-connector {
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    color: #FFFFFF;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: block;
    align-items: center;
    height: 39px;
    padding-top: 5px;
}
.padding-btm-0{
    padding-bottom: 0;
}

.mn-dashboard .syncnmbr {
    width: 44px;
    height: 14px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: #1E54B8;
    display: block;
    margin: 0 auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
}
.connectorExpired{
    line-height: 26px;
    color: red;
    font-weight: 600;
    font-size: 10px !important;
}
.viewmorenewsec {
    color: #ffffff !important;
    margin: 0px auto;
    width: 150px;
}
.selectionProcessnew {
    position: absolute;
    width: 100%;
    height: 72px;
    left: 0;
    background-color: transparent !important;
    margin-top: -7px;
    z-index: 99999999999999999;
}
.scrollingContainer {
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    /* min-height: 253px; */
    max-height: 253px;
}


body .scrollingContainer::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    position: absolute;
    
}

body .scrollingContainer::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5;
    position: absolute;
    visibility: hidden;
}

body .scrollingContainer::-webkit-scrollbar-thumb
{
    background-color: #000000;
}

.infoNewway {
    position: relative;
    cursor: pointer;
    z-index: 99999999999;
    right: 0;
}

a.infoNewway span{
    display: none;
}

.infoNewway:hover span {
    display: inline;
    position: absolute;
    z-index: 99999;
    right: 7px;
    top: 1px;
    background-color: #000000;
    padding: 0px 10px;
    width: 110px;
    text-align: center;
    line-height: 20px;
    }
.infoNewway i {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: block;
    position: relative;
    top: 7px;
}
.infoNewway i:before{
    visibility: hidden;
}
.infoNewway span.text-danger.font-weight-bolder:before {
    content: "";
    width: 13px;
    height: 13px;
    background-color: #000000;
    display: block;
    position: absolute;
    right: -1px;
    transform: rotate(45deg);
    top: 4px;
    z-index: -1;
}
.some-custom-overlay-class{
    z-index: 999999999999999999 !important;
}
.nav-pills .nav-link.active{
    /* border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; */
}

.font-size-9{
    font-size: 9px;
}

.mn-dashboard .nav-pills .nav-link.active .media.pb-3{
    width: 95%;
}
.card-titlenew{
    border-radius: 0px !important;
    border: 0px solid #C6C6C6;
    border-bottom: 1px solid #C6C6C6;
}
.mn-dashboard .connection-info {
    border-radius: 5px;
    overflow: hidden;
}
.pdngtb05{
    padding: 5px 0;
}
.disabledNew {
    background-color: transparent;
    cursor: not-allowed;
}
.info-max{
    margin-bottom: 0;
    min-height: 300px;
    max-height: 350px;
}
a.new-connector {
    background: linear-gradient(135deg, #437FED 6.65%, #1E54B8 91.91%);
    border-radius: 40px;
    color: #FFFFFF;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    display: block;
    align-items: center;
    min-width: 110px;
    text-align: center;
}
.ecomm-erp-dir-container .syncnmbr:nth-child(2){
    display: none;
}
a.text-truncate.text-dark{
    width: 90%;
    display: inline-block;
}

.mn-height55{
    min-height: 55px;
}
.mn-width92{
    min-width:92px;
}
.img-cont-full {
    width: 55px;
    height: 55px;
    background-color: #ffffff;
    border: 1px solid;
    float: left;
    border-radius: 100%;
    margin-top: 5px;
    position: relative;
    z-index: 9;
    border: 1.22222px solid rgba(26, 53, 194, 0.3);
    text-align: center;
    overflow: hidden;
}
.img-cont-1{

}
.img-cont-2 {
    position: relative;
    left: -15px;
    z-index: 5;
}
.img-cont-full img{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    filter: grayscale(100%);
    width: 40px;
}
/* .img-cont-2 img{
    width: 41px;
} */
.connectortab.active .img-cont-full img,
.firstactive.active .img-cont-full img{
    filter:none
}
.mn-dashboard .nav-pills .nav-link.active h5 a{
    color: #437FED !important; 
    font-weight: 900;
}
.mn-dashboard .nav-pills .nav-link h5 a{
    font-weight: 400;
}

.switchToggle .switch img {
    width: 100%;
    position: absolute;
    top: 2px;
    cursor: pointer;
}
.cloneConnector {
    font-weight: 500;
    font-size: 15px;
    border-bottom: 1px solid #5b73e8;
    padding: 2px 5px 0 0;
    line-height: 0px;
}
.nav-link{
    cursor: pointer;
}
.myconnector-businessentity-new {
    background: #f2f4fe;
    border-radius: 8px;
}
.ecomm-erp-dir-container, .erp-ecomm-dir-container {
    display: block;
    width: 100%;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 100% 16px;
}
.erp-ecomm-dir-container {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKgAAAAQCAYAAACC0jSsAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFSSURBVHgB7ZdBToNAFIbfg4XbLqVu8AbuTbDcoDfQ3mB6AusBTLmB3MAjDMWDiAupSy/QPmeSUmvSQYNNZhb/t2L4EiDhC8wjAsAjSa2m51qlLh8RAJ6wcTLxcxSzdkWKQIE3zjZUiVBjDlNXpAgUeKPJi0/ZSt4XKRMAnrFRcsSamVKzbLYbyT/yorEOgYIgcEWKQEEwHIuUx/VcrGyz5Y9Yu/Mdh95eyOwXXg90Y/zlUG9uVK2zZb73L2oSCeuh/qJWd0L89H17KdusmA32Ig/tTbHoluOVWhDz/ak8k8zes6Ls1slKlcx8O9jXSpvpeNKtd1+jaqiXOErX149v++ev5/bdpf/wf22rwZAEgsUMT9iDgnDYfT3tnzG1k72d8BEoCIJjcWJIAkHgitM6BAq80henBYECb/wWpwVTPPAGx3RFPXEC4J1Eq+lIq5HLfwEShflrPk2sJwAAAABJRU5ErkJggg==);
    margin-bottom: 10px;
}
.ecomm-erp-dir-container {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKYAAAAQCAYAAACcGwQfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFISURBVHgB7doxUoNAFAbg9yBjYZUyaINHsHYGww28geYG6wmMB3DkBnIDj4DBA+gNgoVomcouebI7LBIwLWzxf1X+JWR2Z/4hsAPRAdNMTYNcXRHACLz/BmeZCo99fmPiZ5QTxjDpDuhSej5n1cdQhArZ0TsBDIzboV9Kib/jpCCAgTXFRCnBJaaYKCW4hlFKcNHEllKHdinrwq5b3y3K6PHMhtmrmntizq1JWkbJwqbTXN0I8dPfYbkvL5OljScrtSTmO5uZZPEZJanNwUqlzHxt825r5vbSnJ/fru28zc/7Xvh18fDROi6tuVM19+5tC9bm8No82VsigBvMXzl7nDGbFhd1wwsCGFHz8INygkv27k1QTnBFb4Md5QQXcHegW86frZxv4mRDAAPqvcShr5B620jvaVYxPPJpTgCu0FfOIMObRTCOX+zH/6oRcx5DAAAAAElFTkSuQmCC);
}
.imgPlaceholderNew {
    width: 100%;
    height: 100%;
    line-height: 55px;
}
.alert-warning{
    margin: 10px;
}
.slick-slide img {
    margin: 0 auto;
}
.btn-primary {
    color: #fff;
    background-color: #5b73e8;
    border-color: #5b73e8;
}
.btn-rounded {
    border-radius: 30px;
}